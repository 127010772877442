import { Search } from '@air/next-icons';
import { useBreakpointsContext } from '@air/provider-media-query';
import { tailwindMerge } from '@air/tailwind-variants';
import { ComboboxInput } from '@reach/combobox';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { SearchRightButtons } from '~/components/SearchBar/SearchRightButtons';
import { SEARCH_INPUT, SEARCH_INPUT_CONTAINER } from '~/constants/testIDs';
import { useFocusable } from '~/hooks/useFocusable';

export interface SearchInputHandle {
  blur: () => void;
  focus: () => void;
}

interface SearchInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  hasSuggestions: boolean;
  onFocusChange: (isFocused: boolean) => void;
  onEnterPress: (text: string) => void;
  onBackspacePress?: () => void;
  placeholder: string;
  onCloseClick: () => void;
  onClearClick: () => void;
  shortcutsInfo?: ReactNode;
  onSearchActivated: () => void;
}

const HORIZONTAL_PADDING = 8;
const CHILDREN_PADDING = 8;
const SEARCH_ICON_SIZE = 24;
const BASIC_LEFT_PADDING = SEARCH_ICON_SIZE + 2 * HORIZONTAL_PADDING;

const focusedSearchInputContainerClasses =
  'absolute md:relative bg-grey-1 z-1 md:z-[initial] top-0 left-0 right-0 md:top-[initial] md:left-[initial] md:right-[initial] h-14';

const _SearchInput = forwardRef<SearchInputHandle, PropsWithChildren<SearchInputProps>>(
  (
    {
      value,
      onChange,
      hasSuggestions,
      onFocusChange,
      onEnterPress,
      placeholder,
      onBackspacePress,
      onClearClick,
      onCloseClick,
      shortcutsInfo,
      onSearchActivated,
      children,
    }: PropsWithChildren<SearchInputProps>,
    forwardedRef,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isRightButtonFocused, setIsRightButtonFocused] = useState(false);
    const [rightPadding, setRightPadding] = useState(HORIZONTAL_PADDING);
    const [leftPadding, setLeftPadding] = useState(BASIC_LEFT_PADDING);
    const { isAboveMediumScreen } = useBreakpointsContext();
    const isActive = !!value;

    const setFocused = useCallback(() => {
      setIsFocused(true);
      onFocusChange(true);
    }, [onFocusChange]);

    const setBlurred = useCallback(() => {
      setIsFocused(false);
      onFocusChange(false);
    }, [onFocusChange]);

    const [onFocus, onBlur] = useFocusable(setFocused, setBlurred);

    const rightButtonsRef = useRef<HTMLDivElement>(null);
    const childrenContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const isRightButtonsVisible = isFocused || isRightButtonFocused || !!value;
    const isClearBtnVisible = (isRightButtonFocused || isFocused) && !!value;

    useEffect(() => {
      /**
       * This code observes children size changes and sets correct left padding,
       * so that placeholder is moved to left when children are present.
       */
      const element = childrenContainerRef.current;
      const observer = new ResizeObserver((entries) => {
        const rect = entries[0].contentRect;

        if (children && childrenContainerRef.current && rect.width > 0) {
          setLeftPadding(BASIC_LEFT_PADDING + rect.width + CHILDREN_PADDING);
        } else {
          setLeftPadding(BASIC_LEFT_PADDING);
        }
      });

      if (element) {
        observer.observe(element);
      }

      return () => {
        element && observer.unobserve(element);
      };
    }, [children]);

    useImperativeHandle(forwardedRef, () => ({
      blur: () => {
        inputRef?.current?.blur();
      },
      focus: () => {
        inputRef?.current?.focus();
      },
    }));

    useEffect(() => {
      if (!isAboveMediumScreen) {
        inputRef?.current?.focus();
      }
    }, [isAboveMediumScreen]);

    useEffect(() => {
      if (isRightButtonsVisible && rightButtonsRef.current) {
        const paddingWithClearBtn = rightButtonsRef.current.clientWidth + HORIZONTAL_PADDING;
        setRightPadding(paddingWithClearBtn);
        if (value && !isClearBtnVisible) {
          // 60 is clear btn width with vertical separator
          setRightPadding(paddingWithClearBtn - 60);
        }
      } else {
        setRightPadding(HORIZONTAL_PADDING);
      }
    }, [rightButtonsRef, isRightButtonsVisible, isClearBtnVisible, value]);

    useEffect(() => {
      if (!value && isFocused) {
        // when user clears value but its still focus, we want to show suggestions
        // combobox does not support it, so simulate a click to trigger suggestion popover
        setTimeout(() => {
          inputRef.current?.click();
        });
      }
      // call this effect only when value changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
          case 'Enter': {
            event.preventDefault();
            event.stopPropagation();
            onEnterPress((event.target as HTMLInputElement)?.value);
            inputRef.current?.blur();
            break;
          }
          case 'Backspace': {
            const target = event.target as HTMLInputElement;
            const selectionStart = target.selectionStart;
            if (selectionStart === 0) {
              onBackspacePress?.();
            }
            break;
          }
          case 'Escape': {
            event.preventDefault();
            inputRef.current?.blur();
            break;
          }
        }
      },
      [onBackspacePress, onEnterPress],
    );

    const focusedSearchInputClasses = tailwindMerge(
      'border-b-solid h-14 rounded-none border-b border-none border-b-grey-5 py-0 placeholder-grey-8 shadow-none md:h-[initial] md:rounded-t-lg md:border-[initial] md:py-4 md:shadow-[0_2px_8px_rgba(0,0,0,0.2)]',
      hasSuggestions ? '' : 'md:rounded-b-lg',
    );

    return (
      <div
        data-testid={SEARCH_INPUT_CONTAINER}
        className={`${tailwindMerge(
          'group/search-input-container relative flex h-10 items-center rounded md:-mt-1 md:h-12',
          isFocused ? focusedSearchInputContainerClasses : '',
        )}`}
      >
        <Search
          onClick={() => {
            inputRef.current?.focus();
            onSearchActivated();
          }}
          className={`${tailwindMerge(
            'absolute text-grey-9 md:top-3 [&>path]:stroke-[2.5]',
            isFocused ? 'top-3.5' : 'top-2',
          )}`}
          style={{ left: HORIZONTAL_PADDING, width: SEARCH_ICON_SIZE, height: SEARCH_ICON_SIZE }}
        />
        {!!children && (
          <div
            ref={childrenContainerRef}
            className="absolute"
            style={{ left: SEARCH_ICON_SIZE + HORIZONTAL_PADDING + CHILDREN_PADDING }}
          >
            {children}
          </div>
        )}
        <ComboboxInput
          onClick={onSearchActivated}
          data-testid={SEARCH_INPUT}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={tailwindMerge(
            'h-10 w-full rounded border py-2.5 text-16 text-grey-12 placeholder-grey-9 outline-0 transition md:h-[initial] group-hover/search-input-container:[&:not(:focus)]:shadow-[0_0_0_3px] group-hover/search-input-container:[&:not(:focus)]:shadow-grey-4',
            isFocused ? focusedSearchInputClasses : '',
            isActive ? 'border-grey-7' : 'border-grey-5',
          )}
          style={{
            paddingLeft: leftPadding,
            paddingRight: rightPadding,
            transition: 'padding 250ms, border-top-right-radius 250ms, border-top-left-radius 250ms',
          }}
        />
        <div
          ref={rightButtonsRef}
          className={`${tailwindMerge('absolute flex items-center md:top-[initial]', isFocused ? 'top-4' : 'top-2')}`}
          style={{
            right: HORIZONTAL_PADDING,
            opacity: isRightButtonsVisible ? 1 : 0,
            pointerEvents: isRightButtonsVisible ? 'all' : 'none',
          }}
        >
          <SearchRightButtons
            onFocusChange={setIsRightButtonFocused}
            hasValue={!!value}
            isClearBtnVisible={isClearBtnVisible}
            onClearClick={onClearClick}
            onCloseClick={onCloseClick}
          />
        </div>
        {shortcutsInfo && !isRightButtonsVisible && !value ? (
          <div
            className="shortcutsInfo pointer-events-none absolute hidden md:group-hover/search-input-container:flex"
            style={{ right: HORIZONTAL_PADDING }}
            id="#shortcutsInfo"
          >
            {shortcutsInfo}
          </div>
        ) : null}
      </div>
    );
  },
);

_SearchInput.displayName = '_SearchInput';

export const SearchInput = memo(_SearchInput);

SearchInput.displayName = 'SearchInput';
