import { useEffect, useState } from 'react';

import { ShortcutInfo, ShortcutInfoProps } from '~/components/ShortcutInfo';
import { BrowserUtils } from '~/utils/BrowserUtils';

export const CmdShortcutBase = (props: ShortcutInfoProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return <ShortcutInfo {...props}>{BrowserUtils.isMacOS() ? '⌘' : 'ctrl'}</ShortcutInfo>;
};
