import { Close } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo, useRef } from 'react';

import { CLEAR_SEARCH_TEXT_BTN, SEARCH_CLOSE } from '~/constants/testIDs';
import { preventDefaultMouseEvent } from '~/utils/ClickUtils';

interface SearchRightButtonsProps {
  isClearBtnVisible: boolean;
  onCloseClick: () => void;
  onClearClick: () => void;
  onFocusChange: (isFocused: boolean) => void;
  hasValue: boolean;
}

export const SearchRightButtons = memo(
  ({ isClearBtnVisible, onClearClick, onCloseClick, onFocusChange, hasValue }: SearchRightButtonsProps) => {
    const isClearFocused = useRef(false);
    const isCloseFocused = useRef(false);

    return (
      <>
        <div
          className={`${tailwindMerge(
            'items-center',
            isClearBtnVisible ? 'pointer-events-all opacity-100' : 'pointer-events-none opacity-0',
            hasValue ? 'flex' : 'hidden',
          )}`}
        >
          <Button
            data-testid={CLEAR_SEARCH_TEXT_BTN}
            onFocus={() => {
              isClearFocused.current = true;
              onFocusChange(true);
            }}
            onBlur={() => {
              isClearFocused.current = false;
              onFocusChange(isCloseFocused.current);
            }}
            appearance="ghost"
            color="grey"
            size="small"
            onClick={onClearClick}
            // prevent default to not lose focus on search input
            onMouseDown={preventDefaultMouseEvent}
          >
            Clear
          </Button>
          <div className="mx-2 h-4 w-px bg-grey-7" />
        </div>
        <IconButton
          onFocus={() => {
            isCloseFocused.current = true;
            onFocusChange(true);
          }}
          onBlur={() => {
            isCloseFocused.current = false;
            onFocusChange(isClearFocused.current);
          }}
          data-testid={SEARCH_CLOSE}
          icon={Close}
          appearance="ghost"
          color="grey"
          size="small"
          onClick={onCloseClick}
          onMouseDown={preventDefaultMouseEvent}
          label="Close search"
        />
      </>
    );
  },
);

SearchRightButtons.displayName = 'SearchRightButtons';
