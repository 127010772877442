import { TrackSearchLocation, useTrackActivatedSearch, useTrackSearchSuggestionClicked } from '@air/analytics';
import { Combobox } from '@reach/combobox';
import { debounce } from 'lodash';
import {
  ChangeEvent,
  forwardRef,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { usePrevious } from 'react-use';

import { SearchInput, SearchInputHandle } from '~/components/SearchBar/SearchInput';
import { SearchShortcutsInfo } from '~/components/SearchBar/SearchShortcutsInfo';
import { SearchSuggestions } from '~/components/SearchBar/SearchSuggestions';
import { BOARD_UNIQ_CHAR_REGEXP } from '~/components/SearchBar/Suggestion/BoardSuggestion';
import { SEARCH_CONTAINER } from '~/constants/testIDs';
import { isMobileAgent } from '~/utils/PlatformHelpers';

type OnSuggestionClick = (text: string, suggestionType: string) => void;
type ApplyTextSearch = (newTerm: string) => void;
/* Returns true if suggestion click was handled, false otherwise */
type HandleSuggestionEnterClick = (text: string, suggestion: HTMLDivElement | undefined) => boolean;

export type RenderSuggestions = (props: {
  onClick: OnSuggestionClick;
  applyTextSearch: ApplyTextSearch;
  value: string;
}) => ReactNode;

export interface SearchBarProps {
  onSearchTermChange: (search: string) => void;
  onFocusChange?: (isFocused: boolean) => void;
  placeholder: string;
  searchTerm: string | undefined;
  onApplyTextSearch: (term: string) => void;
  onCloseClick: () => void;
  trackLocation: TrackSearchLocation;
  onBackspacePress?: () => void;
  onSearchClear?: () => void;
  handleSuggestionEnterClick?: HandleSuggestionEnterClick;
  renderSuggestions: RenderSuggestions;
  hasSuggestions: boolean;
}

export interface SearchBarHandle {
  focus: () => void;
}

const _SearchBar = forwardRef<SearchBarHandle, PropsWithChildren<SearchBarProps>>(
  (
    {
      searchTerm = '',
      onApplyTextSearch,
      onCloseClick,
      onSearchTermChange,
      handleSuggestionEnterClick,
      onFocusChange,
      placeholder,
      trackLocation,
      children,
      onBackspacePress,
      onSearchClear,
      renderSuggestions,
      hasSuggestions,
    }: PropsWithChildren<SearchBarProps>,
    forwardedRef,
  ) => {
    const inputRef = useRef<SearchInputHandle>(null);

    const { trackSearchSuggestionClicked } = useTrackSearchSuggestionClicked();
    const { trackActivatedSearch } = useTrackActivatedSearch();
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');
    const previousSearchTerm = usePrevious(searchTerm);

    useImperativeHandle(forwardedRef, () => ({
      focus: () => inputRef.current?.focus(),
    }));

    // eslint complains about passing inline function, but only this version works with debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnSearchChange = useCallback(debounce(onSearchTermChange, 300), [onSearchTermChange]);

    const setSearchValue = useCallback(
      (text: string) => {
        setValue(text);
        debouncedOnSearchChange(text);
      },
      [debouncedOnSearchChange],
    );

    /**
     * If a user navigates to a search result page on a public bar, on the server side,
     * searchTerm will be empty but it will have a value client side on first render. To
     * avoid a rehydration issue, we sync the two of them initially on mount AFTER the initial render.
     */
    useEffect(() => {
      // when component is mounted, searchTerm is not ready - it is read from url params from redux, which may not be there yet
      // so later on, when searchTerm is ready, we set it to the value
      if (searchTerm !== previousSearchTerm) {
        setSearchValue(searchTerm ?? '');
      }
    }, [previousSearchTerm, searchTerm, setSearchValue]);

    const _onSearchTermChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const textValue = event.target.value;
        setSearchValue(textValue.replace(BOARD_UNIQ_CHAR_REGEXP, ''));
      },
      [setSearchValue],
    );

    const onSearchClearClick = useCallback(() => {
      setSearchValue('');
      onSearchClear?.();
      inputRef.current?.focus();
    }, [onSearchClear, setSearchValue]);

    const onSearchCloseClick = useCallback(() => {
      setSearchValue('');
      onCloseClick();
      inputRef.current?.blur();
    }, [onCloseClick, setSearchValue]);

    const onSuggestionClick = useCallback<OnSuggestionClick>(
      (text, suggestionType) => {
        trackSearchSuggestionClicked({ text, type: suggestionType });
        setSearchValue('');
      },
      [setSearchValue, trackSearchSuggestionClicked],
    );
    const onApplySearch = useCallback(
      (text: string) => {
        trackSearchSuggestionClicked({ text, type: 'text' });
        setSearchValue(text);
        inputRef.current?.blur();
        onApplyTextSearch(text);
      },
      [setSearchValue, onApplyTextSearch, trackSearchSuggestionClicked],
    );
    useHotkeys(
      'meta+p,ctrl+p',
      (event) => {
        if (inputRef?.current) {
          event.preventDefault();
          inputRef?.current?.focus();
          trackActivatedSearch({ location: trackLocation, isQuickSearch: true });
        }
      },
      [onSearchCloseClick],
    );

    // onSelect has selected text as the argument, but we do no utilize it - we do not know if it was text or board selection
    // Board titles have added U+0000 (NULL) added to titles to distinguish between boards with the same name
    const onSelect = useCallback(() => {
      // without setTimeout blur does not work - Combobox is trying to refocus input after select
      setTimeout(() => {
        inputRef.current?.blur();
      });
    }, []);

    // Combobox sends only information about selected text, thus we do not know if user selected text suggestion or a board
    // As a workaround, board rows has data-boardid set. If user clicks enter, we have to check if highlighted row has this attribute
    // if so, select a board. Otherwise, this is text selection
    const _onSuggestionEnterClick = useCallback(
      (text: string) => {
        const suggestions = document.querySelectorAll<HTMLDivElement>(
          '[data-reach-combobox-list] > [data-highlighted]',
        );
        const handled = handleSuggestionEnterClick?.(text, suggestions[0]);
        if (!handled) {
          onApplySearch(text);
        }
      },
      [handleSuggestionEnterClick, onApplySearch],
    );

    const _onFocusChange = useCallback(
      (isFocused: boolean) => {
        setIsFocused(isFocused);
        onFocusChange?.(isFocused);
      },
      [onFocusChange],
    );

    const onSearchActivated = useCallback(() => {
      trackActivatedSearch({ location: trackLocation, isQuickSearch: false });
    }, [trackLocation, trackActivatedSearch]);

    return (
      <div className="-mb-2 h-10 flex-1 md:h-12" data-testid={SEARCH_CONTAINER}>
        <Combobox openOnFocus onSelect={onSelect}>
          <SearchInput
            onSearchActivated={onSearchActivated}
            onBackspacePress={onBackspacePress}
            placeholder={placeholder}
            onEnterPress={_onSuggestionEnterClick}
            onFocusChange={_onFocusChange}
            ref={inputRef}
            value={value}
            hasSuggestions={hasSuggestions}
            onChange={_onSearchTermChange}
            onClearClick={onSearchClearClick}
            onCloseClick={onSearchCloseClick}
            shortcutsInfo={isMobileAgent ? null : <SearchShortcutsInfo />}
          >
            {children}
          </SearchInput>
          {/* isFocused fixes bug, where suggestions are shown without focus - e.g. when we click keyword on asset details*/}
          {isFocused && hasSuggestions && (
            <SearchSuggestions>
              {renderSuggestions({
                applyTextSearch: onApplySearch,
                onClick: onSuggestionClick,
                value,
              })}
            </SearchSuggestions>
          )}
        </Combobox>
      </div>
    );
  },
);

_SearchBar.displayName = '_SearchBar';

export const SearchBar = memo(_SearchBar);
SearchBar.displayName = 'SearchBar';
