import { AssetSearchFilters, SearchFilterDate, SearchFilterFields } from '@air/api/types';
import { isBoolean, isNil } from 'lodash';
import { useMemo } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

import { ItemTypeFilter } from '~/components/Filters/types';
import { FilterParamNames } from '~/constants/search';
import { useFiltersContext } from '~/providers/FiltersProvider';
import { SeparateLocationParam } from '~/utils/filters/types';
import { customFieldIdFromUrlParam } from '~/utils/filters/urlParamsToFiltersUtils';

export const useFilterParams = () => {
  const { filters: userDefinedFilters, immutableFilters, accountId } = useFiltersContext();

  const filters = useDeepCompareMemo(
    () => ({
      ...userDefinedFilters,
      ...immutableFilters,
    }),
    [userDefinedFilters, immutableFilters],
  );

  const boardParam = useMemo((): AssetSearchFilters['board'] | null => {
    const boardId = filters[FilterParamNames.boardId];
    if (boardId) {
      return { is: boardId };
    }
    return null;
  }, [filters]);

  const libraryParam = useMemo((): AssetSearchFilters['library'] | null => {
    const libraryId = filters[FilterParamNames.libraryId];
    if (libraryId) {
      return { is: libraryId };
    }
    return null;
  }, [filters]);

  const onlyInLibrariesParam = useMemo((): AssetSearchFilters['onlyInLibraries'] | null => {
    const onlyInLibraries = filters[FilterParamNames.onlyInLibraries];
    if (isBoolean(onlyInLibraries)) {
      return { is: onlyInLibraries };
    }
    return null;
  }, [filters]);

  const typesParam = useMemo((): ItemTypeFilter[] => {
    if (filters[FilterParamNames.type]?.length) {
      return filters[FilterParamNames.type] as ItemTypeFilter[];
    }
    return [];
  }, [filters]);

  const customFieldsParam = useMemo((): SearchFilterFields[] => {
    const customFields: SearchFilterFields[] = [];

    if (filters.customFields) {
      Object.keys(filters.customFields).forEach((paramName) => {
        const cfId = customFieldIdFromUrlParam(paramName);
        if (!cfId) return;
        if (paramName.startsWith(FilterParamNames.cfIdAnd)) {
          customFields.push({
            id: cfId,
            filter: {
              and: filters.customFields![paramName],
            },
          });
        } else if (paramName.startsWith(FilterParamNames.cfIdOr)) {
          customFields.push({
            id: cfId,
            filter: {
              or: filters.customFields![paramName],
            },
          });
        } else if (paramName.startsWith(FilterParamNames.cfIdNot)) {
          customFields.push({
            id: cfId,
            filter: {
              not: filters.customFields![paramName],
            },
          });
        }
      });
    }

    return customFields;
  }, [filters.customFields]);

  const createdDateParam = useMemo((): SearchFilterDate | null => {
    if (filters[FilterParamNames.recordedDateStart] || filters[FilterParamNames.recordedDateEnd]) {
      const createdDate: SearchFilterDate = {};

      if (filters[FilterParamNames.recordedDateStart]) {
        createdDate.start = filters[FilterParamNames.recordedDateStart];
      }

      if (filters[FilterParamNames.recordedDateEnd]) {
        createdDate.end = filters[FilterParamNames.recordedDateEnd];
      }

      return createdDate;
    }
    return null;
  }, [filters]);

  const updatedDateParam = useMemo((): SearchFilterDate | null => {
    if (filters[FilterParamNames.modifiedDateStart] || filters[FilterParamNames.modifiedDateEnd]) {
      const updatedDate: SearchFilterDate = {};

      if (filters[FilterParamNames.modifiedDateStart]) {
        updatedDate.start = filters[FilterParamNames.modifiedDateStart];
      }

      if (filters[FilterParamNames.modifiedDateEnd]) {
        updatedDate.end = filters[FilterParamNames.modifiedDateEnd];
      }

      return updatedDate;
    }
    return null;
  }, [filters]);

  const uploadedDateParam = useMemo((): SearchFilterDate | null => {
    if (filters[FilterParamNames.uploadedDateStart] || filters[FilterParamNames.uploadedDateEnd]) {
      const uploadedDate: SearchFilterDate = {};

      if (filters[FilterParamNames.uploadedDateStart]) {
        uploadedDate.start = filters[FilterParamNames.uploadedDateStart];
      }

      if (filters[FilterParamNames.uploadedDateEnd]) {
        uploadedDate.end = filters[FilterParamNames.uploadedDateEnd];
      }

      return uploadedDate;
    }
    return null;
  }, [filters]);

  const takenDateParam = useMemo((): SearchFilterDate | null => {
    if (filters[FilterParamNames.takenDateStart] || filters[FilterParamNames.takenDateEnd]) {
      const takenDate: SearchFilterDate = {};

      if (filters[FilterParamNames.takenDateStart]) {
        takenDate.start = filters[FilterParamNames.takenDateStart];
      }

      if (filters[FilterParamNames.takenDateEnd]) {
        takenDate.end = filters[FilterParamNames.takenDateEnd];
      }

      return takenDate;
    }
    return null;
  }, [filters]);

  const tagsParam = useMemo((): AssetSearchFilters['tag'] | null => {
    if (filters[FilterParamNames.tagIdAnd]?.length) {
      return { and: filters[FilterParamNames.tagIdAnd]! };
    } else if (filters[FilterParamNames.tagIdOr]?.length) {
      return { or: filters[FilterParamNames.tagIdOr]! };
    } else if (filters[FilterParamNames.tagIdNot]?.length) {
      return { not: filters[FilterParamNames.tagIdNot]! };
    }
    return null;
  }, [filters]);

  const importedKeywordsParam = useMemo((): AssetSearchFilters['keyword'] | null => {
    if (filters[FilterParamNames.importedKeywordAnd]?.length) {
      return { and: filters[FilterParamNames.importedKeywordAnd]! };
    } else if (filters[FilterParamNames.importedKeywordOr]?.length) {
      return { or: filters[FilterParamNames.importedKeywordOr]! };
    } else if (filters[FilterParamNames.importedKeywordNot]?.length) {
      return { not: filters[FilterParamNames.importedKeywordNot]! };
    }
    return null;
  }, [filters]);

  const sourcesParam = useMemo((): AssetSearchFilters['source'] | null => {
    if (filters[FilterParamNames.source]?.length) {
      return { or: filters[FilterParamNames.source]! };
    }
    return null;
  }, [filters]);

  const camerasParam = useMemo((): AssetSearchFilters['camera'] | null => {
    const cameraModels = filters[FilterParamNames.cameraModel];
    const cameraMake = filters[FilterParamNames.cameraMake];
    if (cameraMake || !!cameraModels?.length) {
      if (cameraModels?.length) {
        // we allow selecting only one make and multiple models for that make
        return {
          or: cameraModels?.map((model) => ({
            make: filters[FilterParamNames.cameraMake] ?? undefined,
            model,
          })),
        };
      } else {
        // if there is no model, send only make
        return {
          or: [{ make: filters[FilterParamNames.cameraMake] }],
        };
      }
    }
    return null;
  }, [filters]);

  const uploadersParam = useMemo((): AssetSearchFilters['member'] | null => {
    if (filters[FilterParamNames.uploaderId]?.length) {
      return { or: filters[FilterParamNames.uploaderId]! };
    }
    return null;
  }, [filters]);

  const copyrightsParam = useMemo((): AssetSearchFilters['copyright'] | null => {
    if (filters[FilterParamNames.copyright]?.length) {
      return { or: filters[FilterParamNames.copyright]! };
    }
    return null;
  }, [filters]);

  const creatorsParam = useMemo((): AssetSearchFilters['creator'] | null => {
    if (filters[FilterParamNames.creator]?.length) {
      return { or: filters[FilterParamNames.creator]! };
    }
    return null;
  }, [filters]);

  const colorsParam = useMemo((): AssetSearchFilters['color'] | null => {
    if (filters[FilterParamNames.color]?.length) {
      return { or: filters[FilterParamNames.color]! };
    }
    return null;
  }, [filters]);

  const bookmarkedParam = useMemo((): AssetSearchFilters['bookmarked'] | null => {
    if (accountId && filters[FilterParamNames.bookmarked]) {
      return { is: accountId };
    }
  }, [filters, accountId]);

  const isOnBoardsParam = useMemo((): AssetSearchFilters['isOnBoards'] | null => {
    if (!isNil(filters[FilterParamNames.isOnBoards])) {
      return { is: !!filters[FilterParamNames.isOnBoards] };
    }
  }, [filters]);

  const hasVersionsParam = useMemo((): AssetSearchFilters['hasMultipleVersions'] | null => {
    if (!isNil(filters[FilterParamNames.hasMultipleVersions])) {
      return { is: !!filters[FilterParamNames.hasMultipleVersions] };
    }
  }, [filters]);

  const untaggedParam = useMemo((): AssetSearchFilters['untagged'] | null => {
    if (!isNil(filters[FilterParamNames.untagged])) {
      return { is: !!filters[FilterParamNames.untagged] };
    }
  }, [filters]);

  const hasOpenCommentsParam = useMemo((): AssetSearchFilters['hasOpenComments'] | null => {
    if (!isNil(filters[FilterParamNames.hasOpenComments])) {
      return { is: !!filters[FilterParamNames.hasOpenComments] };
    }
  }, [filters]);

  const extensionsParam = useMemo((): AssetSearchFilters['extension'] | null => {
    if (filters[FilterParamNames.ext]?.length) {
      return { or: filters[FilterParamNames.ext]! };
    }
    return null;
  }, [filters]);

  const locationParam = useMemo<SeparateLocationParam | null>(() => {
    const countriesFilter = filters[FilterParamNames.country];
    const statesFilter = filters[FilterParamNames.state];
    const citiesFilter = filters[FilterParamNames.city];

    if (!countriesFilter?.length && !statesFilter?.length && !citiesFilter?.length) return null;

    const locationParam: SeparateLocationParam = {};

    if (countriesFilter?.length) {
      locationParam.country = { or: countriesFilter };
    }

    if (statesFilter?.length) {
      locationParam.state = { or: statesFilter };
    }

    if (citiesFilter?.length) {
      locationParam.city = { or: citiesFilter };
    }

    return locationParam;
  }, [filters]);

  const videoFrameRatesParam = useMemo((): AssetSearchFilters['videoFrameRate'] | null => {
    const frameRates = filters[FilterParamNames.videoFrameRate];
    if (frameRates?.length) {
      return { or: frameRates.map((value) => Number(value)) };
    }
    return null;
  }, [filters]);

  const videoAspectRatiosParam = useMemo((): AssetSearchFilters['videoAspectRatio'] | null => {
    if (filters[FilterParamNames.videoAspectRatio]?.length) {
      return { or: filters[FilterParamNames.videoAspectRatio]! };
    }
    return null;
  }, [filters]);

  const audioCodingsParam = useMemo((): AssetSearchFilters['audioCoding'] | null => {
    if (filters[FilterParamNames.audioCoding]?.length) {
      return { or: filters[FilterParamNames.audioCoding]! };
    }
    return null;
  }, [filters]);

  const audioSampleRatesParam = useMemo((): AssetSearchFilters['audioSampleRate'] | null => {
    const audioSampleRate = filters[FilterParamNames.audioSampleRate];
    if (audioSampleRate?.length) {
      return { or: audioSampleRate.map((value) => Number(value)) };
    }
    return null;
  }, [filters]);

  const params = useDeepCompareMemo(
    () => ({
      audioSampleRatesParam,
      audioCodingsParam,
      customFieldsParam,
      updatedDateParam,
      createdDateParam,
      takenDateParam,
      bookmarkedParam,
      colorsParam,
      uploadersParam,
      boardParam,
      sourcesParam,
      tagsParam,
      uploadedDateParam,
      typesParam,
      extensionsParam,
      isOnBoardsParam,
      hasVersionsParam,
      untaggedParam,
      hasOpenCommentsParam,
      libraryParam,
      onlyInLibrariesParam,
      importedKeywordsParam,
      copyrightsParam,
      creatorsParam,
      camerasParam,
      videoFrameRatesParam,
      locationParam,
      videoAspectRatiosParam,
    }),
    [
      audioSampleRatesParam,
      audioCodingsParam,
      importedKeywordsParam,
      creatorsParam,
      camerasParam,
      videoFrameRatesParam,
      locationParam,
      videoAspectRatiosParam,
      boardParam,
      bookmarkedParam,
      colorsParam,
      createdDateParam,
      customFieldsParam,
      takenDateParam,
      extensionsParam,
      hasOpenCommentsParam,
      hasVersionsParam,
      untaggedParam,
      isOnBoardsParam,
      sourcesParam,
      tagsParam,
      typesParam,
      updatedDateParam,
      uploadedDateParam,
      uploadersParam,
      libraryParam,
      onlyInLibrariesParam,
      copyrightsParam,
      videoAspectRatiosParam,
    ],
  );

  return params;
};
