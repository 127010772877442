import { ShortcutInfo, ShortcutInfoProps } from '~/components/ShortcutInfo';

/**
 * We are using dynamic import because `CmdShortcutBase` checks `isMacOS` which relies on server side window type to be defined
 * Defining `CmdShortcut` directly like the other shortcuts in this file will cause an error stating that client side and server side text do not match
 * @see https://stackoverflow.com/questions/59494037/how-to-detect-the-device-on-react-ssr-app-with-next-js/61519537#61519537
 */
export { CmdShortcutBase as CmdShortcut } from '~/components/CmdShortcutBase';

export const ShiftShortcut = (props: ShortcutInfoProps) => <ShortcutInfo {...props}>⇧</ShortcutInfo>;

export const LeftArrowShortcut = (props: ShortcutInfoProps) => <ShortcutInfo {...props}>{'<-'}</ShortcutInfo>;

export const RightArrowShortcut = (props: ShortcutInfoProps) => <ShortcutInfo {...props}>{'->'}</ShortcutInfo>;

export const UpArrowShortcut = (props: ShortcutInfoProps) => <ShortcutInfo {...props}>↑</ShortcutInfo>;

export const DownArrowShortcut = (props: ShortcutInfoProps) => <ShortcutInfo {...props}>↓</ShortcutInfo>;
